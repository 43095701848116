import { render, staticRenderFns } from "./top.vue?vue&type=template&id=970a367c&scoped=true&"
import script from "./top.vue?vue&type=script&lang=js&"
export * from "./top.vue?vue&type=script&lang=js&"
import style0 from "./top.vue?vue&type=style&index=0&id=970a367c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "970a367c",
  null
  
)

export default component.exports